<template>
  <v-sheet class="mx-auto my-12" max-width="95%" v-if="user">
    <div class="text-center" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate />
      <br />
      {{ $t("お客様の情報を確認中です。処理が完了するまでお待ちください。") }}
    </div>
    <v-card v-if="loading === false">
      <v-card-title>
        {{ $t("クリニック一覧") }}
      </v-card-title>
      <v-card-text>
        <div v-if="clinics.length != 0">
          {{ $t("クリニックを選択すると指示箋の一覧を表示します。") }}
        </div>
        <div v-if="clinics.length == 0">
          {{ $t("登録済みのクリニックがありません。") }}
          <br />
          <br />
          <b>{{ $t("お問い合わせ窓口") }}</b
          ><br />
          {{ $t("お問い合わせは，下記の窓口までお願いいたします。") }}<br />
          {{ $t("担当部署：「クリニックcom」お問い合わせ窓口") }}<br />
          {{ $t("電話番号") }}: <a href="tel:050-3637-3860">050-3637-3860</a
          ><br />
          {{ $t("メール") }}：<a href="mailto:info@eyes.science"
            >info@eyes.science</a
          >
        </div>
      </v-card-text>
      <v-card-text v-if="clinics">
        <v-list-item
          v-for="temp_clinic in clinics"
          :key="temp_clinic.clinic_id"
          @click="
            select_clinic(
              temp_clinic.clinic_id,
              temp_clinic.patientNumber,
              temp_clinic.patient_id
            )
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-hospital-building</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              >{{ temp_clinic.clinic_name }} {{ $t("患者様番号") }}
              {{ temp_clinic.patientNumber }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: "about",
  data: () => ({
    loading: true,
    clinics: [],
  }),
  computed: {
    user() {
      return this.$store.state.userModule.data[this.$store.getters.user.uid];
    },
  },

  created: async function () {
    this.loading = true;
    this.fetchUsers();
  },
  methods: {
    select_clinic: function (clinic_id, patientNumber, patient_id) {
      this.$router.push({
        name: "priscription_list",
        params: { id: clinic_id },
        query: { patientNumber: patientNumber, patient_id: patient_id },
      });
    },
    fetchUsers: async function () {
      const result = await this.$store.dispatch(
        "userModule/fetchById",
        this.$store.getters.user.uid
      );
      if (result.clinics.length == 0) {
        this.$router.push({ name: "search" });
      }
      for (var i = 0; i < result.clinics.length; i++) {
        const user_clinic = result.clinics[i];
        if (user_clinic.is_unable == undefined) {
          this.clinics.push(user_clinic);
        }
        if (user_clinic.is_unable == false) {
          this.clinics.push(user_clinic);
        }
      }

      this.loading = false;
    },
  },
};
</script>
